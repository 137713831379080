<template>
  <div class="wrap">
    <div id="basicInfo">
      <div class="leftbox">
        <ul class="info_panel" v-if="basicInfo.doctor_info">
          <li class="mb20">
            <img
              @error="notFind($event, 80)"
              :src="basicInfo.doctor_info.photo"
              alt=""
            />
          </li>
          <li style="font-size: 18px">
            <b>{{ basicInfo.doctor_info.true_name }}</b>
          </li>
        </ul>
        <ul class="info_panel info_menu">
          <li
            :class="{ active: currentView == 'basic' }"
            @click="currentView = 'basic'"
          >
            {{ lang_data == 1 ? "基本信息" : "The basic information" }}
          </li>
          <li
            :class="{ active: currentView == 'auth' }"
            @click="currentView = 'auth'"
          >
            {{ lang_data == 1 ? "认证信息" : "Authentication information" }}
          </li>
          <li
            :class="{ active: currentView == 'setting' }"
            @click="currentView = 'setting'"
          >
            {{ lang_data == 1 ? "账号密码" : "The account password" }}
          </li>
          <li
            v-if="isAdmin"
            :class="{ active: currentView == 'prepayments' }"
            @click="currentView = 'prepayments'"
          >
            {{ lang_data == 1 ? "预付款" : "Credit in the Prepaid Account" }}
          </li>
          <li
            v-if="isAdmin"
            :class="{ active: currentView == 'bill' }"
            @click="currentView = 'bill'"
          >
            {{ lang_data == 1 ? "结算单" : "bill" }}
          </li>
        </ul>
      </div>
      <div
        class="rightbox"
        v-loading="loading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      >
        <components
          :is="currentView"
          :info="basicInfo"
          v-if="basicInfo.doctor_info"
          @update="getInfo"
          @renew="getInfo"
        ></components>
      </div>
    </div>
  </div>
</template>

<script>
import basic from "./basic";
import setting from "./setting";
import auth from "./auth";
import bill from "./bill";
import prepayments from "./prepayments";
export default {
  name: "userindex",
  components: {
    basic,
    setting,
    auth,
    prepayments,
    bill,
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      loading: false,
      basicInfo: {},
      currentView: "basic",
      userData: {},
    };
  },
  computed: {
    isAdmin() {
      let isadmin = localStorage.is_admin > 0;
      return isadmin;
    },
  },
  mounted() {
    this.getInfo();
    if (localStorage.userData) {
      this.userData = JSON.parse(localStorage.userData);
    }
  },
  methods: {
    getInfo() {
      // 获取个人资料
      this.loading = true;
      this.$axios.get("/ucenter/getinfo").then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        }
        this.basicInfo = res.data;
        this.basicInfo.doctor_info.id = this.userData.id;
        this.basicInfo.doctor_info.clinic_id = this.userData.clinic_id;
        localStorage.userData = JSON.stringify(this.basicInfo.doctor_info);
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.wrap {
  height: calc(100% - 70px);
}
._full {
  background: #f7f8f9;
  display: flex;
  flex: auto;
}
#basicInfo {
  width: 1250px;
  height: 100%;
  padding: 20px 0;
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;

  .info_panel {
    background: #fff;
    width: 246px;
    padding: 30px 0;
    text-align: center;
    color: #333;
    margin-bottom: 14px;
    box-shadow: 0 0 6px 2px #efefef;
    border: 1px solid #efefef;
    img {
      border-radius: 50%;
    }
  }
  .info_menu {
    font-size: 16px;
    // padding: 44px 0;
    color: #505050;
    line-height: 50px;
    height: 100%;
    box-shadow: 0 0 6px 2px #efefef;
    border: 1px solid #efefef;
    li {
      cursor: pointer;
      &.active {
        color: #009ae3;
      }
    }

    margin-bottom: 0px;
  }

  .leftbox {
    margin-right: 17px;
    width: 250px;
    flex: none;
    display: flex;
    flex-direction: column;
  }
  .rightbox {
    width: 100%;
    min-height: 400px;
    background: #fff;
    overflow: hidden;
    height: 100%;
    box-shadow: 0 0 6px 2px #efefef;
    border: 1px solid #efefef;
  }
}
</style>
